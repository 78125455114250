body {
  /* TODO: Figure out where does #121212 body bg 
  color comes from in Dark mode */
  background-color: var(--colors-neutralBg);
}

body,
html {
  overscroll-behavior-y: none;
  overflow: hidden;
}

body {
  pointer-events: unset !important;
}
